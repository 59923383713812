import {defineAsyncComponent} from "vue";

const TheRoleDataTable = defineAsyncComponent(() =>
    import("./ui/data-table/DataTable.vue")
)

const TheRolePermissionOptionsFormSidePanel = defineAsyncComponent(() =>
    import("./ui/side-panel/RolePermissionOptionsFormSidePanel.vue")
)

export {TheRoleDataTable, TheRolePermissionOptionsFormSidePanel};
